<template>
  <div class="pay_scheme">
    <!--<div class="pay_scheme_tip">-->
      <!--<div class="tip_left">-->
        <!--<span class="tip_l_sp" @click="() => {goPage('/cases')}">{{$t('casesDetail.casesChildren.common.bl')}}</span> &gt;-->
        <!--<span class="tip_left_sp" @click="() => {goPage('/cases/detail')}">{{$t('casesDetail.casesChildren.common.blxq')}}</span> &gt;-->
        <!--{{$t('casesDetail.casesChildren.paymentScheme.scf')}}-->
      <!--</div>-->
      <!--<div class="tip_right" @click="goBack">-->
        <!--&lt;&lt; {{$t('casesDetail.casesChildren.common.fhsyy')}}-->
      <!--</div>-->
    <!--</div>-->
    <div class="case_information">
      <p class="case_number">{{$t('cases.cases.blh')}}:{{casesInfo.caseNumber}}</p>
      <p class="ml20">|</p>
      <p class="ml20 fz16rem">{{casesInfo.clinicName}}</p>
      <p class="ml20">|</p>
      <p class="ml20 fz16rem">{{casesInfo.productName}}</p>
    </div>

    <div class="pay_scheme_con">
      <div class="pay_con_left">
        <div
          class="l_con_pic"
          :style="{backgroundImage: `url('${casesInfo.photo ? $PicPrefix + casesInfo.photo : defPhoto}')`}">
        </div>
        <div class="l_con_right">
          <div class="l_c_r_head">{{casesInfo.realName}}
            <span
              class="l_c_r_h_icon"
              :style="{backgroundImage: `url('${casesInfo.sex === '1' ? BOY_ICON : GIRL_ICON}')`}"
            ></span>
            <p class="ml40">{{$t('casesDetail.casesChildren.paymentScheme.dqrsc')}}</p>
          </div>
          <p class="l_c_r_h_p">
            <span class="r_sr_icon"></span>
            {{casesInfo.age}}（{{casesInfo.birthdayStr}}）
            <span class="r_m_icon"></span>
            {{casesInfo.mobile}}
          </p >
          <p class="l_c_r_h_p l_c_r_h_mp">
            <span class="r_d_icon"></span>
            {{casesInfo.address}}
          </p>
          <!--<p class="l_c_r_h_p l_c_r_h_c3">{{$t('casesDetail.casesChildren.paymentScheme.blh')}}：{{casesInfo.caseNumber}}</p >-->
          <!--<ul class="l_con_r_ul">-->
            <!--<li class="r_ul_single">-->
              <!--<div-->
                <!--class="r__single_pic"-->
                <!--:style="casesInfo.clinicPic ? {backgroundImage: `url('${PIC_PREFIX + casesInfo.clinicPic}')`} : {}"-->
              <!--&gt;</div>-->
              <!--<p>{{casesInfo.clinicName}}</p >-->
            <!--</li>-->
            <!--<p class="s_line"></p >-->
            <!--<li class="r_ul_single">-->
              <!--<div-->
                <!--class="r__single_pic"-->
                <!--:style="casesInfo.productPic ? {backgroundImage: `url('${PIC_PREFIX + casesInfo.productPic}')`} : {}"-->
              <!--&gt;</div>-->
              <!--<p>{{casesInfo.productName}}</p >-->
            <!--</li>-->
          <!--</ul>-->
        </div>
      </div>
      <div class="pay_con_right">
        <!--<div class="pay__r_head">-->
          <!--<span class="r_h_sp">{{$t('casesDetail.casesChildren.paymentScheme.scf')}}</span>-->
          <!--<span class="r_h_strong">{{productionFee}}{{$t('casesDetail.casesChildren.paymentScheme.y')}}</span>-->
        <!--</div>-->
        <!--<p class="pay__r_tit">-->
          <!--{{$t('casesDetail.casesChildren.paymentScheme.sxye')}}：-->
          <!--{{accountAmount}}-->
          <!--{{$t('casesDetail.casesChildren.paymentScheme.y')}}-->
        <!--</p >-->
        <!--<div class="pay__r_box">-->
          <!--{{$t('casesDetail.casesChildren.paymentScheme.srzfmm')}}：<p>-->
          <!--<input type="password" :placeholder="$t('cases.createUser.qsr')" autocomplete="new-password" v-model="tradePwd">-->
        <!--</p >-->
        <!--</div>-->
        <p class="pay__r_tip">
          {{$t('casesDetail.casesChildren.paymentScheme.qrhksjg')}}
        </p>
        <div class="pay__r_foo">
          <div class="main_theme_color_btn_white main_big_btn" @click="goPage('/cases/detail')">{{$t('casesDetail.casesChildren.common.fh')}}</div>
          <div class="main_theme_color_btn main_big_btn"
               @click="toPay">{{$t('casesDetail.casesChildren.paymentScheme.qrsc')}}</div>
        </div>
      </div>
    </div>
    <confirm-modal
      :confirmObj="{
        tipText: $t('casesDetail.casesChildren.paymentScheme.sfqrsc') + '？',
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('casesDetail.casesChildren.paymentScheme.qrsc'),
        initText: $t('casesDetail.casesChildren.paymentScheme.qrhksjg'),
      }"
      @confirmSubmit="confirmSubmit"
      @confirmHide="confirmHide"
      v-show="isShow"
    />
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import {payProductionFee, payDesignFee, accountByUser} from 'common/api/cases';
  import {getSystormType,getSystormClinicDetail,getClinicProductDetail} from 'common/api/public';
  import {defPhoto, BOY_ICON, GIRL_ICON} from 'common/js/requireImage';
  import {notifyMsg} from 'common/js/util';
  import FullLoading from 'components/full-loading/full-loading';
  import ConfirmModal from 'components/confirm-modal/confirm';
  import { mapGetters, mapActions} from 'vuex';
  import {get,extend} from 'lodash';

  export default {
    data() {
      return {
        defPhoto,
        BOY_ICON,
        GIRL_ICON,
        isLoading: false,
        isShow: false,
        casesInfo: {},
        productionFee: 0,
        accountAmount: 0,
      }
    },
    computed: {
      ...mapGetters({
        'commonCaseDetail': 'getCommonCaseDetail',//病例详情
      }),
      ...mapState({
        userMsg: state => state.userMsg
      })
    },
    methods: {
      ...mapActions({
        'getCommonCaseDetail' : 'actionGetCommonCaseDetail',//病例详情
      }),
      async getPageDetail(casesId){
        let data;
        await this.getCommonCaseDetail(casesId).then((caseData) => {
          data = caseData;
        });
        this.isLoading = true;
        const {productPicture, clinicPicture, productName, clinicId, clinicName, caseNumber} = data;
        const {realName, photo, address = {}, age, birthdayStr, mobile, sex} = data.userInfo;
        const {provinceName = '', cityName = '', areaName = ''} = address;
        this.casesInfo = {
          caseNumber,
          clinicName,
          clinicId,
          productName,
          clinicPic: clinicPicture,
          productPic: productPicture,
          realName,
          photo,
          address: `${provinceName}-${cityName}-${areaName}-${address.address || ''}`,
          age,
          sex,
          birthdayStr,
          mobile
        };
        accountByUser({
          userId: data.clinicId,
          currency: 'CNY'
        }).then(data => {
          this.isLoading = false;
          this.accountAmount = data.amount || 0;
        }).catch(() => this.isLoading = false);
        getClinicProductDetail({productId: data.productId,clinicId: data.clinicId}).then((data) => {
          this.productionFee = get(data, 'productionFee');
        })
      },
      goPage(url) {
        this.$router.push({
          path: url,
          query: extend({}, this.$route.query)
        });
      },
      confirmHide() {
        this.isShow = false;
      },
      confirmSubmit() {
        const curePlanId = this.$route.query.curePlanId;
        if (!curePlanId) {
          this.isShow = false;
          return notifyMsg(this, 'warning', this.$t('casesDetail.casesChildren.paymentScheme.zfsb'));
        }
        const config = {
          id: curePlanId,
        };
        this.isLoading = true;
        payProductionFee(config).then(() => {
          this.isLoading = false;
          this.isShow = false;
          notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.paymentScheme.zfcg')).then(() => {
            this.goPage('/cases/detail')
          });
        }).catch(() => {
          this.isLoading = false;
          this.isShow = false;
        });
      },
      toPay() {
        const {productionFee,accountAmount} = this;
        if(+productionFee > +accountAmount) {
          return  this.$message({
            message: this.$t('casesDetail.casesChildren.paymentScheme.sxyebz'),
            type: 'warning',
            offset: 120
          });
        }
        this.isShow = true;
      }
    },
    components: {
      FullLoading,
      ConfirmModal
    },
    created() {
      const casesId = this.$route.query.caseId;
      document.title = this.$t('casesDetail.casesChildren.paymentScheme.qrsc');
      this.getPageDetail(casesId);
    },
  }
</script>

<style lang="scss" scoped>
  .case_information{
    display: flex;
    margin-bottom: 0.2rem;
    align-items: center;
    .case_number{
      font-size: 0.24rem;
    }
  }
  .pay_scheme {
    width: 13.44rem;
    margin: 0 auto;
    color: $main_theme_color_333;
    font-size: 0.16rem;
    .pay_scheme_tip {
      padding: 0.2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $main_theme_color_333;
      .tip_left {
        display: flex;
        align-items: center;
        .tip_left_sp {
          margin: 0 0.06rem;
          cursor: pointer;
        }
        .tip_l_sp {
          margin-right: 0.06rem;
          cursor: pointer;
        }
      }
      .tip_right {
        color: $main_theme_color;
        cursor: pointer;
      }
    }
    .pay_scheme_con {
      background-color: #fff;
      border-radius: 0.08rem;
      padding: 0.43rem 0.69rem 0.42rem 0.55rem;
      display: flex;
      align-items: center;
      .pay_con_left {
        margin-right: 1.15rem;
        flex: 1;
        display: flex;
        .l_con_pic {
          min-width: 1.12rem;
          width: 1.12rem;
          height: 1.12rem;
          margin-right: 0.42rem;
          border-radius: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
        .l_con_right {
          padding-top: 0.07rem;
          span {
            display: inline-block;
          }
          .l_c_r_head {
            font-size: 0.22rem;
            margin-bottom: 0.2rem;
            display: flex;
            align-items: center;
            .l_c_r_h_icon {
              margin-left: 0.08rem;
              width: 0.18rem;
              height: 0.18rem;
              background-size: 100% 100%;
            }
          }
          .l_c_r_h_p {
            display: flex;
            color: #666;
            line-height: 0.24rem;
            .r_d_icon{
              min-width: 0.2rem;
            }
          }
          .l_c_r_h_mp {
            margin: 0.2rem 0 0.23rem;
          }
          .l_c_r_h_c3 {
            color: #333;
          }
          .l_con_r_ul {
            margin-top: 0.38rem;
            display: flex;
            align-items: center;
            .r_ul_single {
              width: 1.31rem;
              text-align: center;
              font-size: 0.14rem;
              .r__single_pic {
                height: 0.69rem;
                margin-bottom: 0.14rem;
                background-color: #F3F3F3;
                background-size: 100% 100%;
              }
            }
            .s_line {
              width: 0.01rem;
              height: 0.3rem;
              background-color: #D9D9D9;
              margin: 0 0.3rem 0.3rem;
            }
          }
        }
      }
      .pay_con_right {
        background-color: #FAFAFA;
        min-width: 4.84rem;
        padding: 0.3rem 0.39rem 0.31rem 0.41rem;
        span {
          display: inline-block;
        }
        .pay__r_head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 0.3rem;
          border-bottom: 0.01rem solid #D8D8D8;
          margin-bottom: 0.25rem;
          .r_h_sp {
            font-size: 0.2rem;
          }
          .r_h_strong {
            font-size: 0.3rem;
          }
        }
        .pay__r_tit {
          text-align: right;
          font-size: 0.14rem;
          margin-bottom: 0.3rem;
        }
        .pay__r_box {
          display: flex;
          align-items: center;
          font-size: 0.14rem;
          margin-bottom: 0.13rem;
          p {
            flex: 1;
            background-color: #F4F4F4;
            padding-left: 0.19rem;
            box-sizing: border-box;
            border-radius: 0.04rem;
            input {
              flex: 1;
              outline: none;
              border: none;
              padding: 0.14rem 0;
              box-sizing: border-box;
            }
          }
        }
        .pay__r_tip {
          color: $main_theme_color;
          font-size: 0.14rem;
          text-align: right;
          margin-bottom: 0.29rem;
          white-space: nowrap;
        }
        .pay__r_foo {
          display: flex;
          justify-content: space-between;
          span {
            width: 1.7rem;
            height: 0.52rem;
            line-height: 0.52rem;
            text-align: center;
            cursor: pointer;
            -webkit-border-radius: 1rem;
            -moz-border-radius: 1rem;
            border-radius: 1rem;
          }
          .foo_back {
            border: 0.01rem solid #511E7A;
            border-radius: 2rem;
            color: #511E7A !important;
            background-color: #fff !important;
          }
          .foo_ok {
            color: #fff !important;
            background-color: #511E7A !important;
          }
        }
      }
    }
  }
</style>